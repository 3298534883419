import { Button, Modal } from '@components/ui'
import { FC } from 'react'
import cn from 'classnames'
import s from './PrivateSalesModal.module.css'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'

export interface PrivateSalesModalProps {
  open?: boolean
  closeModal?: () => void
}

export const PrivateSalesModalView: FC<PrivateSalesModalProps> = ({
  closeModal,
}) => {
  const { t } = useTranslation('privateSales')

  const router = useRouter()

  const goToCreateAccount = () => {
    router.push('/register')
    closeModal()
  }

  const goToLogin = () => {
    router.push('/login')
    closeModal()
  }

  return (
    <div className={cn(s.container)}>
      <div className={cn(s.content)}>
        <h2 className={s.title}>{t(`title`)}</h2>
        <p className={s.messageVpPeriod}>{t(`messageVpPeriod`)}</p>
        <p className={s.suggestionMessage}>{t(`suggestionMessage`)}</p>
        <div className={s.buttonsContainer}>
          <Button className={s.button} onClick={goToLogin}>
            {t(`connect`)}
          </Button>
          <Button className={s.button} onClick={goToCreateAccount}>
            {t(`createAccount`)}
          </Button>
        </div>
      </div>
    </div>
  )
}

const PrivateSalesModal: FC<PrivateSalesModalProps> = ({ ...props }) => {
  return (
    <Modal
      className={s.modalWrapper}
      open={props.open}
      onClose={props.closeModal}
      detectClickOutside={false}
    >
      <PrivateSalesModalView {...props} />
    </Modal>
  )
}

export default PrivateSalesModal
